
// @ts-nocheck


export const localeCodes =  [
  "en",
  "it",
  "es",
  "pt",
  "fr",
  "de",
  "zh",
  "no",
  "ja",
  "da",
  "el",
  "nl",
  "sv"
]

export const localeLoaders = {
  "en": [],
  "it": [],
  "es": [],
  "pt": [],
  "fr": [],
  "de": [],
  "zh": [],
  "no": [],
  "ja": [],
  "da": [],
  "el": [],
  "nl": [],
  "sv": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English"
    },
    {
      "code": "it",
      "name": "Italiano"
    },
    {
      "code": "es",
      "name": "Español"
    },
    {
      "code": "pt",
      "name": "Português"
    },
    {
      "code": "fr",
      "name": "Français"
    },
    {
      "code": "de",
      "name": "Deutsch"
    },
    {
      "code": "zh",
      "name": "中文 (普通话)"
    },
    {
      "code": "no",
      "name": "Norsk"
    },
    {
      "code": "ja",
      "name": "日本語"
    },
    {
      "code": "da",
      "name": "Dansk"
    },
    {
      "code": "el",
      "name": "Ελληνικά"
    },
    {
      "code": "nl",
      "name": "Nederlands"
    },
    {
      "code": "sv",
      "name": "Svenska"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "language-preference",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": []
  },
  {
    "code": "it",
    "name": "Italiano",
    "files": []
  },
  {
    "code": "es",
    "name": "Español",
    "files": []
  },
  {
    "code": "pt",
    "name": "Português",
    "files": []
  },
  {
    "code": "fr",
    "name": "Français",
    "files": []
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": []
  },
  {
    "code": "zh",
    "name": "中文 (普通话)",
    "files": []
  },
  {
    "code": "no",
    "name": "Norsk",
    "files": []
  },
  {
    "code": "ja",
    "name": "日本語",
    "files": []
  },
  {
    "code": "da",
    "name": "Dansk",
    "files": []
  },
  {
    "code": "el",
    "name": "Ελληνικά",
    "files": []
  },
  {
    "code": "nl",
    "name": "Nederlands",
    "files": []
  },
  {
    "code": "sv",
    "name": "Svenska",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
