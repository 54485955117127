export default defineNuxtPlugin(async (nuxtApp) => {
  const user = useUser();

  if (user.value === undefined) {
    const response = await fetchCurrentUser();
    user.value = response?.data;
  }

  if (user.value) {
    useBugsnag().setUser(user.value.id, user.value.email, user.value.full_name);

    const { $i18n } = nuxtApp;

    if (user.value.language && user.value.language !== $i18n.locale.value) {
      await $i18n.setLocale(user.value.language);
    }
  }
});
